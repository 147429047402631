import React from "react"
import PlayIcon from "components/PlayIcon"
import styled from "styled-components"
import Img from "gatsby-image"

const ImageWrapper: any = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  user-select: none;
  cursor: pointer;
`
const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

interface Props {
  onClick?: Function
  imageUrl: any
  customStyle?: any
  size?: string
}

const EmbedVideoThumb: React.FC<Props> = props => {
  let imageSrc
  if (!props.imageUrl.childImageSharp) {
    imageSrc = props.imageUrl
  }
  return (
    <ImageWrapper onClick={props.onClick} style={props.customStyle}>
      {props.imageUrl.childImageSharp ? (
        <Img
          fluid={props.imageUrl.childImageSharp.fluid}
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            margin: "0px",
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <Image src={imageSrc} />
      )}
      <PlayIcon size={props.size} />
    </ImageWrapper>
  )
}

export default EmbedVideoThumb
