import styled from "styled-components"
import Play from "images/play.inline.svg"
import theme from "components/layout/theme"

const PlayIcon = styled(Play)`
  position: absolute;
  width: ${props => (props.size === "small" ? "28px" : "72px")};
  height: ${props => (props.size === "small" ? "28px" : "72px")};
  color: ${theme.colors.primary};
  left: 50%;
  margin-left: ${props => (props.size === "small" ? "-14px" : "-36px")};
  top: 50%;
  margin-top: ${props => (props.size === "small" ? "-14px" : "-36px")};
`

export default PlayIcon
